import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter, useParams } from "react-router-dom";
import Moment from "moment";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  AppBar,
  Grid,
  Card,
  CardContent,
  InputLabel,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@material-ui/core";
import { pdf } from "@react-pdf/renderer";

import IconButton from "@material-ui/core/IconButton";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import ZoomInOutlinedIcon from "@material-ui/icons/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@material-ui/icons/ZoomOutOutlined";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";

import * as userActions from "../../../../../actionCreators/User";
import * as spillActions from "../../../../../actionCreators/Spill";
import * as clientActions from "../../../../../actionCreators/Client";
import * as downloadActions from "../../../../../actionCreators/Download";
import * as contractorActions from "../../../../../actionCreators/Contractor";

import EditNote from "../../../../Dashboard/components/content/Spills/EditNote";
import CreateNote from "../../../../Dashboard/components/content/Spills/CreateNote";
import EditReserve from "../../../../Dashboard/components/content/Spills/EditReserve";
import AdminViewTab from "../../../../Dashboard/components/content/Spills/AdminViewTab";
import CreateReserve from "../../../../Dashboard/components/content/Spills/CreateReserve";
import NoteCardAccordion from "../../../../Dashboard/components/content/Spills/NoteCardAccordion";
import {
  ROLES,
  convert,
  Constants,
  isPESUser,
  isPESUser_2,
  getPublicIp,
  orderStatuses,
  canSeeVoidOption,
  isContractorUser,
  statusVisibility,
  canSeeStampsOrAmounts,
  sortSpillReservesByDate,
  getAgency,
  getFormattedTime,
  CONTRACTOR_PROMPT_MESSAGE_TEXT,
  SPILL_STATUSES,
  timeConvertMinsToHHMM,
  timeConvertMinsToHHMMForETA,
  timeConvertHHMMtoMins,
  isPESUser_3,
  noteFieldsMapper,
} from "../../../../../utils";
import {
  voidSpillText as voidText,
  spillContractorModalText as contractorModalText,
} from "../../../../../utils/language/english";

import SpillPdf from "./SpillPdf";

import {
  Prompt,
  TabDetail,
  WholeScreenModal,
  UpdateContractorsPrompt,
  CustomSnackBar,
} from "../../../../../Components";
import HistoryTabPanel from "./History/HistoryTabPanel";
import CustomMenu from "../../../../../Components/CustomMenu";
import PaginationButtons from "../../../../../Components/PaginationButtons";
import { transformSpillNotes } from "./helperSpills";

const useStylesTablePagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useTableStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: "400px",
    overflow: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(47 125 50 / 66%)",
      borderRadius: 6,
      minHeight: "50px",
    },
  },
  tableLink: {
    textDecoration: 'none',
    color: '#2f7d32',
  },
  tableHeader: {
    backgroundColor: '#2f7d32c2 !important',
  },
  tableHeaderRow: {
    borderRadius: '10px 10px 0px 0px',
  },
  tableHeaderCell: {
    backgroundColor: '#2f7d32c2 !important',
    color: "#fff",
    fontWeight: 600,
    padding: '15px 0',
  },
  tableBodyRow: {
    padding: '20px 0',
  },
  tableBodyCell: {
    textTransform: 'capitalize',
    height: 40,
  },
  tableOverallRow: {
    height: 50,
  },
  tableOverallCell: {
    color: '#5e5e5e',
    fontWeight: 600,
  },
  divider: {
    border: "none",
    height: "1px",
    margin: '20px 0',
    flexShrink: 0,
    backgroundColor: "#dadada",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootLoader: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  positiveEta: {
    color: "green",
  },
  negativeEta: {
    color: "red",
  },
  spillPaper: {
    marginTop: 10,
    padding: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  leftAlign: {
    textAlign: "left",
  },
  detailHeading: {
    color: "#295115",
    fontSize: "15px",
    fontWeight: 800,
  },
  header: {
    color: "#b17830",
    fontSize: "20px",
    fontWeight: 800,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  linkButton: {
    color: "#295115",
    fontSize: "15px",
    fontWeight: 800,
    textDecoration: "underline",
    cursor: "pointer",
  },
  totalArea: {
    marginLeft: "16px",
    fontSize: "15px",
    fontWeight: "800",
    padding: "7px 0",
  },
  divider: {
    border: "none",
    height: "3px",
    margin: 0,
    flexShrink: 0,
    backgroundColor: "#484848",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalCard: {
    backgroundColor: "#E0E0E0",
  },
  spacedTotalCard: {
    backgroundColor: "#E0E0E0",
    margin: "10px 0px",
  },
  totalLabel: {
    fontSize: "15px",
    fontWeight: "800",
    padding: "7px 0",
    marginRight: "5px",
  },
  dateText: {
    fontSize: "0.875rem",
  },
  downloadBanner: {
    padding: "10px",
    textAlign: "left",
    backgroundColor: "white",
    color: "#295115",
    fontSize: "13px",
    lineHeight: "1.75",
    transition: "all 0.5s",
    border: "1px solid #E0E0E0",
    margin: "10px 0px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
  downloadBannerLink: {
    color: "#295115",
    fontSize: "13px",
    fontWeight: 800,
    cursor: "pointer",
  },
  rightAlign: {
    textAlign: "right",
  },
  inlineBlock: {
    display: "inline-block",
  },
  notesListScroll: {
    maxHeight: "500px",
    overflow: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(47 125 50 / 66%)",
      borderRadius: 6,
      minHeight: "50px",
    },
  },

  inActive: {
    fontSize: "13px",
    color: "#616161",
  },
  inActiveHeading: {
    fontSize: "15px",
    color: "#616161",
    fontWeight: 800,
  },
  tabPanelPaper: {
    padding: "1rem 3rem",
  },
  etaContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    background: "#7cd27c21",
    borderRadius: "7px",
    padding: "10px",
    rowGap: "20px",
  },
  etaSubContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "20px",
  },
  etaHeading: {
    fontWeight: "bold",
    width: "100px",
  },
  etaValue: {
    fontStyle: "italic",
  },
  bottomSpace: {
    marginBottom: "10px",
  },
  usersForEmail: {
    fontSize: "20px",
  },
  boldText: {
    fontWeight: "600",
  },
  paginationWrapper: {
    textAlign: "right",
    margin: "10px 0px",
  },
  notePaginationWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  paginationContainer: {
    display: "flex",
  },
  paginationToolBar: {
    display: "flex",
    alignItems: "center",
  },
  paginationSelect: {
    padding: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Paper>{children}</Paper>}
    </div>
  );
}
function TablePaginationActions(props) {
  const classes = useStylesTablePagination();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={count && page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!count || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ViewSpill = ({
  id,
  onSearch,
  history,
  updateSpill,
  adminsData,
  getAdmins,
  closeSpill,
  downloadAll,
  currentUser,
  currentClient,
  getOrganization,
  currentSpill,
  closeViewSpill,
  getCompleteSpill,
  downloadAdminFiles,
  currentSpillServices,
  contractorsWithAddress,
  getContractorsWithAddress,
  getClientAssociatedNotesServices,
  currentNoteHistory,
  getNoteHistory,
  download,
  createSingleNote,
  newNoteCreation,
  updateStatus,
  getStatuses,
  statuses,
  getSpillAdminAttachmentHistory,
  spillAdminAttachmentHistory,
  getPaginatedNotes,
  paginatedNotes,
  totalPrintableNotes,
  getTotalNotesForPrintSpill,
  uploadNoteVideos,
  clearSpillData,
  noteVideosUpload,
  updateSpillForAddingArrivedOnService,
  spillContractorsUpdateData,
  updateStatusState,
  editNoteState,
  getIcsTeamTerminal,
  noteWorkTimeValidationFail,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [spillNotes, setSpillNotes] = useState([]);
  const myRef = React.useRef(null);
  const [spillReserves, setSpillReserves] = useState([]);
  const [usersToEmail, setUsersToEmail] = useState([]);
  const [services, setServices] = useState([]);
  const [spillData, setSpillData] = useState(currentSpill?.data);
  const [admins, setAdmins] = useState([]);
  const [selectedVideoFiles, setSelectedVideoFiles] = useState([]);
  const [spillContractors, setSpillContractors] = useState([]);
  const lastSpillId = useRef(currentSpill?.data?.spill?.id || undefined);
  const [contractorModalState, setContractorModalState] = useState({
    pendingContractors: [],
    message: "",
    visible: false,
  });
  const [gpsFieldState, setGPSFieldState] = useState({
    lat: null,
    long: null,
  });
  const [showEditNote, setShowEditNote] = useState(false);
  const [showEditReserve, setShowEditReserve] = useState(false);
  const [showCreateReserve, setShowCreateReserve] = useState(false);
  const [showNoteCards, setShowNoteCards] = useState(true);
  const [showCreateNote, setShowCreateNote] = useState(false);
  // const [allowCloseSpill, setAllowCloseSpill] = useState(false);
  const [currentNote, setCurrentNote] = useState();
  const [currentReserve, setCurrentReserve] = useState();
  const [spillValues, setspillValues] = useState({
    need_5800: "",
    is_waste: "",
    has_msds: "",
    is_hazmat: "",
    response_sent: "",
    subrogation: "",
    send_attachment: "",
    drainImpacted: "",
    waterwayImpacted: "",
    mapNeeded: "",
  });
  const [tabValue, setTabValue] = useState(0);
  const [tabList, setTabList] = useState([]);
  // const [pdfInfo, setPdfInfo] = useState({
  //   href: null,
  //   name: null,
  // });
  const [attachmentHistory, setAttachmentHistory] = useState({
    data: [],
    error: null,
    loading: false,
  });

  const [totalNotes, setTotalNotes] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [spillNotesData, setSpillNotesData] = useState({
    totalAmount: 0,
    totalHours: 0,
    total_impermeable_area: 0,
  });
  const [newNoteData, setNewNoteData] = useState({
    amount: 0,
    hour: 0,
  });
  const [isAppendNoteLast, setIsAppendNoteLast] = useState(false);
  const [updateSpillNote, setUpdateSpillNote] = useState(false);
  const [spillVoid, setSpillVoid] = useState({
    showVoidPrompt: false,
    isVoid: false,
  });
  const [noteLoader, setNoteLoader] = useState(false);
  const [spillStatuses, setSpillStatuses] = useState([]);
  const [printNotes, setPrintNotes] = useState([]);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [openAreaCoverModal, setOpenAreaCoverModal] = React.useState();
  const [totalArea, setTotalArea] = useState({});
  const [notesSummary, setNotesSummary] = useState([]);
  const [totalTally, setTotalTally] = useState(0);
  const TeamTerminalData = useSelector(
    (state) => state.client.clientTeamTerminal?.data
  );

  document.title = currentSpill?.data?.spill?.job_no || "fetching...";


  useEffect(() => {
    getCompleteSpill({ id, ...{ mode: "view" } });
    getOrganization({ id: currentUser?.data?.org_id });
    getStatuses();
    getAdmins({ filter: "none" });
    // call getIcsTeamTerminal only when teamId and terminalId is selected
    if (showTeamDropdown == true && teamId && terminalId) {
      getIcsTeamTerminal({ team_id: teamId, terminal_id: terminalId });
    }
  }, []);

  useEffect(() => {
    if (paginatedNotes?.data) {
      const storedNoteIdStr = localStorage.getItem("note_id");
      // Check if storedNoteIdStr is a valid integer string
      if (/^\d+$/.test(storedNoteIdStr)) {
        const storedNoteIdNumber = parseInt(storedNoteIdStr, 10);
        const note = paginatedNotes?.data.find(
          (item) => item.id === storedNoteIdNumber
        );
        if (note) {
          setTimeout(() => {
            setTabValue(4);
            setCurrentNote(note);
            setOpenAreaCoverModal(true);
            setShowEditNote(true);
            setShowNoteCards(false);
          }, 500);
          localStorage.removeItem("note_id");
        }
      }

      if (paginatedNotes?.totalArea?.length) {
        const total_permeable_area =
          parseInt(paginatedNotes?.totalArea[0]?.total_permeable_area, 10) || 0;
        const total_impermeable_area =
          parseInt(paginatedNotes?.totalArea[0]?.total_impermeable_area, 10) ||
          0;
        setTotalArea({
          total_permeable_area,
          total_impermeable_area,
          total: total_permeable_area + total_impermeable_area,
        });
      }
    }
  }, [paginatedNotes]);

  useEffect(() => {
    if (currentSpill?.data) {
      setSpillData(currentSpill?.data);
      getContractorsWithAddress();
      getClientAssociatedNotesServices({
        org_id: currentSpill?.data?.spill?.org_id,
        spill_id: id,
      });
      setGPSFieldState({
        lat: currentSpill?.data?.spill?.latitude,
        long: currentSpill?.data?.spill?.longitude,
      });
      // Invalid attempt to spread non iterable instance
      Array.isArray(currentSpill?.data?.spillContractors) && setSpillContractors([...currentSpill?.data?.spillContractors]);

      // getTotalNotesForPrintSpill({ spillId: currentSpill?.data?.spill?.id });
      setspillValues((val) => {
        return {
          ...val,
          need_5800: currentSpill?.data?.spill?.need_5800 ? "Yes" : "No",
          is_waste: currentSpill?.data?.spill?.is_waste ? "Yes" : "No",
          has_msds: currentSpill?.data?.spill?.has_msds ? "Yes" : "No",
          is_hazmat: currentSpill?.data?.spill?.is_hazmat ? "Yes" : "No",
          response_sent: currentSpill?.data?.spill?.response_sent
            ? "Yes"
            : "No",
          subrogation: currentSpill?.data?.spill?.subrogation ? "Yes" : "No",
          send_attachment: currentSpill?.data?.spill?.send_attachment
            ? "Yes"
            : "No",
          drainImpacted: currentSpill?.data?.spill?.drain_impacted
            ? "Yes"
            : "No",
          waterwayImpacted: currentSpill?.data?.spill?.waterway_impacted
            ? "Yes"
            : "No",
          mapNeeded: currentSpill?.data?.spill?.map_needed ? "Yes" : "No",
        };
      });
    }
  }, [currentSpill?.data?.spill?.id]);

  useEffect(() => {
    if (updateStatusState?.success) {
      if (updateStatusState?.status === SPILL_STATUSES?.CLOSED_CLOSED_STATUS) {
        setSpillData({
          ...spillData,
          spill: {
            ...spillData?.spill,
            closed_on: updateStatusState?.closedOn,
          },
        });
      }
    }
  }, [updateStatusState]);

    // useEffect
    const contractorOptions = useMemo(() => {
      let contractor = [];
      if (contractorsWithAddress?.data?.length) {
        contractor = contractorsWithAddress.data.map((contractor) => {
          let allOptions;
  
          if (contractor.entity_id) {
            const value = {
              value: `${contractor.id} ${contractor.addressId || ""}`,
              label: `${contractor.name} - ${contractor.tier_level} - (${contractor.city},${contractor.state})`,
              addressId: contractor.addressId || "",
              contractor_id: contractor.id,
            };
  
            allOptions = { ...allOptions, ...value };
          } else {
            const value = {
              value: `${contractor.id}`,
              label: `${contractor.name} - ${contractor.tier_level} - (${contractor.city},${contractor.state})`,
              contractor_id: contractor.id,
            };
            allOptions = { ...allOptions, ...value };
          }
  
          return allOptions;
        });
      }
  
      return contractor;
    }, [contractorsWithAddress.data.length]);

  useEffect(() => {
    if (currentSpill.data) {
      setSpillData(currentSpill?.data);
      if (currentSpill?.data?.spill?.reserves) {
        const { reserves } = currentSpill?.data?.spill;
        const sortedReserves = sortSpillReservesByDate(reserves);
        
        setSpillReserves(sortedReserves);
      }
      if (currentSpill?.data?.spill?.recipients) {
        let users = currentSpill?.data?.spill?.recipients.map((recipient) => {
          const user = {
            value: recipient?.user_id,
            label: recipient?.user?.full_name,
            organization: recipient?.user?.client_organization?.name,
            address: recipient?.user?.client_organization?.address,
            phone: recipient?.user?.phone,
            city: recipient?.user?.client_organization?.city,
            email: recipient?.user?.email,
            deletedAt: recipient?.deleted_at,
          };
          return user;
        });

        users = users?.filter((user) => user?.deletedAt === null);
        setUsersToEmail(users);
      }
      // if (currentSpill?.data?.spill?.spill_notes?.length > 0) {
      //   let spillNotesArray = [];
      //   setSpillNotes([]);
      //   currentSpill.data.spill.spill_notes.forEach((note) => {
        //     spillNotesArray.push(note);
        //   });

        //   setSpillNotes(spillNotesArray);
        // }
        
      if (currentSpill?.data?.spill?.spill_notes_data?.count > 0) {
        setTotalNotes(currentSpill?.data?.spill?.spill_notes_data?.count);
      }
      
      if (currentSpill?.data?.spill?.spill_notes_data) {
        setSpillNotesData({
          totalAmount: parseFloat(
            currentSpill?.data?.spill?.spill_notes_data?.amount
            ),
          totalHours: parseFloat(
            currentSpill?.data?.spill?.spill_notes_data?.hour
            ),
            total_impermeable_area:
            currentSpill?.data?.spill?.spill_notes_data?.total_impermeable_area,
            total_permeable_area:
            currentSpill?.data?.spill?.spill_notes_data?.total_permeable_area,
        });
      }

      if (currentSpillServices?.data) {
        setServices(currentSpillServices?.data);
      }
    }
  }, [currentSpill, currentSpillServices?.data]);

  useEffect(() => {
    if (adminsData?.data?.length > 0) {
      setAdmins(adminsData?.data);
    }
  }, [adminsData?.data]);

  useEffect(() => {
    if (currentSpillServices?.data?.length > 0) {
      setServices(currentSpillServices?.data);
    }
  }, [currentSpillServices]);

  useEffect(() => {
    if (paginatedNotes?.data?.length > 0) {
      const totalPrintNotes = [];
      setSpillNotes(paginatedNotes?.data);
      for (let index = 0; index < paginatedNotes?.data.length; index++) {
        const note = paginatedNotes?.data[index];
        const {
          hour,
          amount,
          rate,
          created_at,
          service_type,
          description,
          deleted_at,
        } = note;
        if (note.hasOwnProperty("hour")) {
          totalPrintNotes.push({
            hour,
            amount,
            rate,
            created_at,
            service_type,
            description,
            deleted_at,
          });
        }
      }
      setPrintNotes(totalPrintNotes);
      setNoteLoader(false);
    }
  }, [paginatedNotes]);

  useEffect(() => {
    const { data, totalTally } = transformSpillNotes(spillNotes);
    setNotesSummary(data);
    setTotalTally(totalTally);
  }, [spillNotes]);

  // Fetch spill notes  
  useEffect(() => {
    if (!currentSpill?.data?.spill?.id) {
      return;
    }
    if (lastSpillId.current !== currentSpill?.data?.spill?.id) {
      if (currentSpill?.data?.spill?.id) {
        // getTotalNotesForPrintSpill({ spillId: currentSpill?.data?.spill?.id });
      }

      getPaginatedNotes({
        spillId: currentSpill?.data?.spill?.id,
      });
      lastSpillId.current = currentSpill?.data?.spill?.id;
    }
  }, [currentSpill?.data]);

  useEffect(() => {
    if (currentUser?.data?.role) {
      let list = [];
      let role = currentUser?.data?.role;
      if (!isContractorUser(role?.role)) {
        list = [
          { label: "Overview", index: 0 },
          { label: "Connections", index: 1 },
          { label: "Miscellany", index: 2 },
          { label: "Reserves", index: 5 },
          { label: "Notes & Attachments", index: 4 },
        ];
        if (isPESUser(role?.role)) list.push({ label: "Admin", index: 3 });
      } else {
        list.push({ label: "Overview", index: 0 });
        list.push({ label: "Admin", index: 3 });
        setTabValue(3);
      }
      showHistory() && list.push({ label: "History", index: 6 });

      // Show information tab only for the PES users and the super user
      isPESUser_3(role?.role) && list.push({ label: "Information", index: 8 });
      setTabList(list);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentSpill?.success && spillVoid.isVoid) {
      // getTotalNotesForPrintSpill({ spillId: spillData?.spill?.id });
      !currentSpill?.data?.spill?.reserves?.length && setSpillReserves([]);

      if (currentSpill.data) {
        setspillValues((val) => {
          return {
            ...val,
            need_5800: currentSpill?.data?.spill?.need_5800 ? "Yes" : "No",
            is_waste: currentSpill?.data?.spill?.is_waste ? "Yes" : "No",
            has_msds: currentSpill?.data?.spill?.has_msds ? "Yes" : "No",
            is_hazmat: currentSpill?.data?.spill?.is_hazmat ? "Yes" : "No",
            response_sent: currentSpill?.data?.spill?.response_sent
              ? "Yes"
              : "No",
            subrogation: currentSpill?.data?.spill?.subrogation ? "Yes" : "No",
            send_attachment: currentSpill?.data?.spill?.send_attachment
              ? "Yes"
              : "No",
            drainImpacted: currentSpill?.data?.spill?.drain_impacted
              ? "Yes"
              : "No",
            waterwayImpacted: currentSpill?.data?.spill?.waterway_impacted
              ? "Yes"
              : "No",
            mapNeeded: currentSpill?.data?.spill?.map_needed ? "Yes" : "No",
          };
        });
      }
    }
  }, [currentSpill, spillVoid.isVoid]);
  
  // useEffect(() => {
    //   const activeAdmins =
  //     spillData?.admin?.length > 0
  //       ? spillData?.admin?.filter(
  //           (adminUser) => adminUser?.is_removed === false
  //         )
  //       : [];
  //   let isActiveAdminsAttachmentsApproved =
  //     activeAdmins?.length > 0
  //       ? activeAdmins?.map((activeAdmin) => {
  //           let allAttachmentsApproved = false;
  //           if (activeAdmin?.spill_attachments?.length > 0) {
  //             allAttachmentsApproved = activeAdmin?.spill_attachments?.every(
  //               (attachment) => attachment?.status === 'approve'
  //             );
  //           }

  //           return allAttachmentsApproved;
  //         })
  //       : null;
  //   if (isActiveAdminsAttachmentsApproved === null) {
  //     isActiveAdminsAttachmentsApproved = true;
  //   } else {
  //     isActiveAdminsAttachmentsApproved =
  //       isActiveAdminsAttachmentsApproved?.length > 0
  //         ? isActiveAdminsAttachmentsApproved?.every((item) => item === true)
  //         : false;
  //   }

  //   setAllowCloseSpill(isActiveAdminsAttachmentsApproved);
  // }, [spillData?.admin]);

  useEffect(() => {
    const formData = new FormData();
    const spillId = spillData?.spill?.id;

    if (selectedVideoFiles?.length > 0 && newNoteCreation?.newNoteId !== null) {
      // call micro-service for uploading video
      selectedVideoFiles?.forEach((file) => {
        const fileItem = new File([file], file?.name, {
          type: file?.type,
        });
        formData.append("file", fileItem, `note#${file.name}`);
      });

      const videoFilesData = newNoteCreation?.newNoteAttachments?.filter(
        (noteAttachment) => noteAttachment?.name?.includes("mp4")
      );

      const newNoteId = newNoteCreation?.newNoteId;

      formData.append("newNoteId", JSON.stringify(newNoteId));
      formData.append("spillId", JSON.stringify(spillId));
      formData.append("videoFilesData", JSON.stringify(videoFilesData));

      uploadNoteVideos(formData);
    }
    if (newNoteCreation?.success === true) {
      // getTotalNotesForPrintSpill({ spillId: spillData?.spill?.id });
    }
  }, [newNoteCreation?.success]);

  useEffect(() => {
    if (spillContractorsUpdateData?.success && currentSpill) {
      const updatedResponseSpillContractors =
        currentSpill?.data?.spillContractors;
      setSpillContractors(updatedResponseSpillContractors);
      setContractorModalState({
        message: "",
        pendingContractors: [],
        visible: false,
        showGPSPopup: true
      });
    }
  }, [spillContractorsUpdateData, currentSpill]);

  useEffect(() => {
    if (spillAdminAttachmentHistory?.data?.length > 0) {
      setAttachmentHistory({
        data: spillAdminAttachmentHistory?.data,
        error: spillAdminAttachmentHistory?.error,
        loading: spillAdminAttachmentHistory?.loading,
      });
    }
  }, [spillAdminAttachmentHistory]);

  const viewAdminAttachmentHistory = (attachmentId) => {
    getSpillAdminAttachmentHistory(attachmentId);
  };

  useEffect(() => {
    if (newNoteCreation?.success) {
      setSpillNotesData({
        totalAmount: spillNotesData?.totalAmount + newNoteData?.amount,
        totalHours: spillNotesData?.totalHours + newNoteData?.hour,
      });
      setTotalNotes(totalNotes + 1);
      let lastPage = Math.ceil(totalNotes / rowsPerPage);
      if (Number.isInteger(totalNotes / rowsPerPage)) {
        lastPage = lastPage + 1;
      }
      handleNotesAndAttachmentsChangePage(rowsPerPage, lastPage - 1);
    }
  }, [newNoteCreation, newNoteData]);

  useEffect(() => {
    if (editNoteState?.success && editNoteState?.loading === false) {
      // getTotalNotesForPrintSpill({ spillId: spillData?.spill?.id });
      handleNotesAndAttachmentsChangePage(rowsPerPage, page);
    }
  }, [editNoteState]);

  useEffect(() => {
    if (statuses && currentSpill?.data?.spill?.id) {
      const data = statuses;

      let orderSpillStatuses = orderStatuses(data);

      setSpillStatuses(orderSpillStatuses);
    }
  }, [
    currentSpill.data,
    currentSpill?.data?.spill?.status,
    currentSpill.data?.admin,
    currentSpillServices.data,
    statuses,
  ]);

  const terminalId = currentSpill?.data?.spill?.terminal_id;

  let spillOrg = currentSpill?.data?.spill?.client_organization || {};
  const hideError = () => {
    setErrorMessage("");
  };
  const reportingReqData =
    currentSpill?.data?.spill?.reportingRequirement || [];
  const facilityData = currentSpill?.data?.spill?.facility || [];
  const isIcsClient = currentClient?.data?.is_ics_client;
  const wasteHandlingData = currentSpill?.data?.spill?.wasteHandling;
  const showTeamDropdown =
    currentSpill?.data?.spill?.client_organization?.is_ics_client;
  const teamId = currentSpill?.data?.spill?.team_id;

  const handleStatusChange = (status) => {
    updateStatus({
      status,
      spill_id: spillData?.spill.id,
      job_no: spillData?.spill?.job_no,
    });
  };

  const getTotal = (key) => {
    let count = 0;
    for (let note of spillNotes) {
      count += parseFloat(note[key]) || 0;
    }
    return count;
  };

  const getTotalAttachments = (type) => {
    let count = 0;
    if (spillData?.admin && (type === "admin" || type === "all")) {
      for (const admin of spillData?.admin) {
        count += admin.spill_attachments.length;
      }
    }
    if (type === "note" || type === "all") {
      // for (const note of spillNotes) {
      //   count += note?.note_attachments?.length || 0;
      // }
      count +=
        currentSpill?.data?.spill?.spill_notes_data?.totalNotesAttachments;
    }
    return count;
  };

  const voidPromptHandler = (displayPrompt) => {
    setSpillVoid({ ...spillVoid, showVoidPrompt: displayPrompt });
  };

  const clearSpillDataHandler = () => {
    lastSpillId.current = null;
    setSpillVoid({ showVoidPrompt: false, isVoid: true });
    lastSpillId.current = null;
    clearSpillData({
      spillId: currentSpill.data?.spill?.id,
      connectionId: currentSpill.data?.connection?.connectionId,
      userId: currentSpill.data?.spill?.user_id,
      legacyId: currentSpill.data?.spill?.legacy_id,
      jobNo: currentSpill.data?.spill?.job_no,
    });
  };

  const showAllButton = () => {
    return false;
    //TODO: Will be deleted once this change is finalized.
    // if (
    //   ![
    //     ROLES.CONTRACTOR_ADMIN,
    //     ROLES.CONTRACTOR_USER,
    //     ROLES.CORPORATE_LOGGER,
    //   ].includes(currentUser.data?.role.role)
    // ) {
    //   return true;
    // }
    // return false;
  };
  const showHistory = () => {
    if (isPESUser(currentUser.data?.role.role)) return true;
    return false;
  };

  const showAdmin = (adminData, user) => {
    if (currentSpill.data.spill.is_legacy && adminData.is_main) {
      return true;
    }
    if (adminData.contractor_id === user.contractor_id) {
      if (
        user.role.role === ROLES.CONTRACTOR_ADMIN ||
        (!adminData.contractor_address_id && !user.contractor_address_id) ||
        adminData.contractor_address_id === user.contractor_address_id
      ) {
        return true;
      }
    }
    return false;
  };

  const isCoporatePersonnel = () => {
    if (currentUser.data?.role?.role === ROLES.CORPORATE_USER) {
      return true;
    } else if (currentUser.data?.role?.role === ROLES.CORPORATE_ACCOUNTING) {
      return true;
    } else if (currentUser.data?.role?.role === ROLES.CORPORATE_LOGGER) {
      return true;
    } else if (currentUser.data?.role?.role === ROLES.CORPORATE_ADMIN) {
      return true;
    } else {
      return false;
    }
  };

  const isContractor = () => {
    if (currentUser.data?.role?.role === ROLES.CONTRACTOR_ADMIN) {
      return true;
    } else if (currentUser.data?.role?.role === ROLES.CONTRACTOR_USER) {
      return true;
    } else {
      return false;
    }
  };

  const isContractorInactive = () => {
    if (!spillData) {
      return true;
    }
    if (
      currentUser.data?.role.role === ROLES.CONTRACTOR_ADMIN ||
      currentUser.data?.role.role === ROLES.CONTRACTOR_USER
    ) {
      // Is the contractor active in this Spill?
      let contractorObjs = [];
      let spillContractors = spillData?.spillContractors;
      let filter = null;
      if (currentUser.data?.role.role === ROLES.CONTRACTOR_USER) {
        filter = (o) =>
          o.contractor_id === currentUser.data.contractor_id &&
          ((!o.addressId && !currentUser.data.contractor_address_id) ||
            o.addressId === currentUser.data.contractor_address_id);
      } else {
        filter = (o) => o.contractor_id === currentUser.data.contractor_id;
      }
      if (spillContractors?.length) {
        contractorObjs = spillContractors.filter(filter);
      }
      if (
        contractorObjs.filter((o) => o.is_inactive).length ===
        contractorObjs.length
      ) {
        return true;
      }
    }
    return false;
  };

  function a11yProps(index) {
    return {
      id: `edit-tab-${index}`,
      "aria-controls": `edit-tabpanel-${index}`,
    };
  }

  const getTime = (key) => {
    const format = "MM-DD-YYYY h:mm a";

    const dispatchServices = currentSpill?.data?.spill?.spill_notes.filter(
      (note) => !!note[key]
    );

    return dispatchServices[0].created_at
      ? Moment(new Date(dispatchServices[0].created_at))?.format(format)
      : Moment(new Date())?.format(format);
  };

  const getETA = (key) => {
    const dispatchServices = currentSpill?.data?.spill?.spill_notes.filter(
      (note) => !!note[key]
    );

    return dispatchServices.length > 0
      ? timeConvertMinsToHHMM(dispatchServices[0][key])
      : null;
  };

  const getDifferenceETA = (projectedETA, actualETA) => {
    let difference =
      timeConvertHHMMtoMins(actualETA) - timeConvertHHMMtoMins(projectedETA);
    difference = timeConvertMinsToHHMMForETA(difference);
    return difference;
  };
  const getEtaBox = () => {
    return (
      (getETA("projected_eta") || getETA("actual_eta")) && (
        <div className={classes.etaContainer}>
          {getETA("projected_eta") && (
            <div className={classes.etaSubContainer}>
              <div className={classes.etaHeading}>Projected ETA</div>
              <div className={classes.etaValue}>
                {`${getETA("projected_eta")} at ${getTime("projected_eta")}`}
              </div>
            </div>
          )}
          {getETA("actual_eta") && (
            <div className={classes.etaSubContainer}>
              <div className={classes.etaHeading}>Actual ETA</div>
              <div className={classes.etaValue}>
                {`${getETA("actual_eta")} at ${getTime("actual_eta")}`}
              </div>
            </div>
          )}
          {getETA("actual_eta") &&
            getDifferenceETA(getETA("projected_eta"), getETA("actual_eta")) && (
              <div className={classes.etaSubContainer}>
                <div className={classes.etaHeading}>Calculated ETA</div>
                <div className={classes.etaValue}>
                  <span
                    className={
                      getDifferenceETA(
                        getETA("projected_eta"),
                        getETA("actual_eta")
                      ).includes("-")
                        ? classes.positiveEta
                        : classes.negativeEta
                    }
                  >
                    {`${getDifferenceETA(
                      getETA("projected_eta"),
                      getETA("actual_eta")
                    )} ${
                      getDifferenceETA(
                        getETA("projected_eta"),
                        getETA("actual_eta")
                      ).includes("-")
                        ? "(Arrived Early)"
                        : "(Arrived Late)"
                    }`}
                  </span>
                </div>
              </div>
            )}
        </div>
      )
    );
  };

  const checkIfEditable = (note) => {
    const permission =
      (currentUser.data?.role?.permission.edit_all_notes ||
        (currentUser.data?.role.permission.edit_own_notes &&
          note.user_id === currentUser.data?.id)) &&
      (!!note.service_id ||
        !!note.amount ||
        !note.description?.includes("was removed from assigned contractors")) &&
      note.service_type !== Constants.DELETION_SERVICE_NAME &&
      note.service_type !== Constants.DOWNLOAD_ATTACHMENT_SERVICE_NAME;

    return permission;
  };
  const checkIfDisabled = (note) => {
    const serviceExists = note?.service_id
      ? currentSpillServices.data?.some(
          (service) => service?.id === note.service_id
        )
      : true;
    return !serviceExists;
  };

  const handleNotesAndAttachmentsChangePage = (updatedRowsPerPage, newPage) => {
    setPage(newPage);
    setIsPageChanged(true);
    const spill_id = currentSpill?.data?.spill?.id;
    if (spill_id) {
      getPaginatedNotes({
        spillId: currentSpill?.data?.spill?.id,
        page: newPage,
        limit:
          updatedRowsPerPage === null ||
          updatedRowsPerPage === undefined ||
          typeof updatedRowsPerPage === "object"
            ? rowsPerPage
            : updatedRowsPerPage,
      });
    }
  };

  const handleNotesAndAttachmentsChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    handleNotesAndAttachmentsChangePage(parseInt(event.target.value, 10), 0);
  };

  // dont allow to create a note if the time conflicts
  const createNewNote = (newNote, sendEmail) => {
    setShowCreateNote(false);
    setShowNoteCards(true);
    const noteFileData = [];
    const formData = new FormData();
    formData.append(
      "noteToCreate",
      JSON.stringify({
        ...newNote,
        created_at: new Date(),
      })
    );
    newNote?.attachments.forEach((file) => {
      formData.append("file", file, `note#${file.name}`);

      noteFileData.push({
        noteIndex: 0,
      });
    });

    formData.append("noteFileData", JSON.stringify(noteFileData));
    formData.append("spillId", currentSpill?.data?.spill?.id);
    formData.append("spillUrl", window.location.href);
    formData.append("sendEmail", sendEmail);
    formData.append("isAppendNoteLast", isAppendNoteLast);
    createSingleNote(formData);
    setNewNoteData({
      amount: newNote?.amount,
      hour: newNote?.hour,
    });
  };

  const createNoteBtn = () =>
    showNoteCards &&
    [
      ROLES.SUPER_USER,
      ROLES.PES_ADMIN,
      ROLES.PES_ACCOUNTING_ADMIN,
      ROLES.CORPORATE_LOGGER,
    ].includes(currentUser?.data?.role?.role) && (
      <div style={{ textAlign: "right", margin: "10px 0px" }}>
        <Button
          color="primary"
          onClick={() => {
            const getNoteData = localStorage.getItem("note_data");
            const noteIdsToMatch = getNoteData
              ? JSON.parse(getNoteData)
                  .filter(
                    (item) => item.job_no === currentSpill?.data?.spill?.job_no
                  )
                  .map((item) => item.note_id)
              : [];
            noteWorkTimeValidationFail(null);
            if (noteIdsToMatch.length) {
              const isMatchingNote = paginatedNotes?.data?.find((item) =>
                noteIdsToMatch.includes(item.id)
              );
              if (isMatchingNote) {
                setErrorMessage(
                  `Release coverage area is pending to add in the contractor arrival on site note-${isMatchingNote?.id}, please add value to add/edit notes.`
                );
                setShowNoteCards(true);
                setShowCreateNote(false);
              } else {
                setShowNoteCards(false);
                setShowCreateNote(true);
              }
            } else {
              setShowNoteCards(false);
              setShowCreateNote(true);
            }
          }}
          startIcon={<AddBoxIcon />}
        >
          Create Note
        </Button>
      </div>
    );

  const handleEditNoteClick = (note) => {
    const getNoteData = localStorage.getItem("note_data");

    const isMatchingNote = getNoteData
      ? JSON.parse(getNoteData).find(
          (item) =>
            item.job_no === currentSpill?.data?.spill?.job_no &&
            note?.id !== item?.note_id
        )
      : null;

    noteWorkTimeValidationFail(null);
    if (isMatchingNote) {
      setShowEditNote(false);
      setShowNoteCards(true);
      setErrorMessage(
        `Release coverage area is pending to add in the contractor arrival on site note-${isMatchingNote?.note_id}, please add value to add/edit notes.`
      );
    } else {
      setShowNoteCards(false);
      setShowEditNote(true);
      setCurrentNote(note);
    }
  };

  const getConditionalFields = () => {
    const toReturn = [];
    !currentUser?.data?.role?.role?.includes("Contractor") &&
      toReturn.push(
        ...[
          {
            heading: "Point of Contact",
            subValues: [spillData?.spill?.contact],
          },
          {
            heading: "Responsible Party",
            subValues: [spillData?.spill?.responsible],
          },
          {
            heading: "GPS Coordinates",
            subValues: [
              {
                heading: "Latitude",
                value: spillData?.spill?.latitude,
              },
              {
                heading: "Longitude",
                value: spillData?.spill?.longitude,
              },
            ],
          },
          {
            heading: "Impacts",
            subValues: [
              {
                heading: "Drain Impacted",
                value: spillValues.drainImpacted,
              },
              {
                heading: "Waterway Impacted",
                value: spillValues.waterwayImpacted,
              },
            ],
          },
          {
            heading: "Driver",
            subValues: [
              {
                heading: "Driver Name",
                value: spillData?.spill?.driver_name,
              },
              {
                heading: "Driver Phone",
                value: spillData?.spill?.driver_phone,
              },
            ],
          },
          {
            heading: "Onsite POC",
            subValues: [
              {
                heading: "Onsite POC Name",
                value: spillData?.spill?.onsite_poc_name,
              },
              {
                heading: "Onsite POC Phone",
                value: spillData?.spill?.onsite_poc_phone,
              },
            ],
          },
          {
            heading: "Tractor #",
            subValues: [spillData?.spill?.tractor],
          },
          {
            heading: "Trailer #",
            subValues: [spillData?.spill?.trailer],
          },
          {
            heading: "Pro #",
            subValues: [spillData?.spill?.pro],
          },
        ]
      );

    return toReturn;
  };

  // useEffect(() => {
  //   setNoteLoader(false);
  //   if (printNotes?.length) {
  //     let updatedSpill = {
  //       ...currentSpill,
  //       data: {
  //         ...currentSpill?.data,
  //         spill: {
  //           ...currentSpill?.data?.spill,
  //           spill_notes: printNotes?.filter(
  //             (note) => note?.deleted_at === null
  //           ),
  //         },
  //       },
  //     };
  //     generatePdfDocument(updatedSpill);
  //   }
  // }, [currentSpill?.data, printNotes?.length]);

  const generatePdfDocument = () => {
    try {
      setPdfLoader(true);
      setTimeout(async () => {
        const updatedSpill = {
          ...currentSpill,
          data: {
            ...currentSpill?.data,
            spill: {
              ...currentSpill?.data?.spill,
              spill_notes: printNotes?.filter(
                (note) => note?.deleted_at === null
              ),
            },
          },
        };
        const blob = await pdf(
          <SpillPdf data={updatedSpill} userMetaData={currentUser} notesSummary={notesSummary} totalTally={totalTally} />
        ).toBlob();

        const blobUrl = URL.createObjectURL(blob);
        setPdfLoader(false);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `Spill-${currentSpill?.data?.spill?.job_no}.pdf`;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
      }, 50);
    } catch (error) {
      setPdfLoader(false);
    }
  };

  // Works Only For "Contractor Arrived Onsite" and "Response Authorization" Service
  const checkSelectedServiceHandler = (service) => {
    const serviceName = service?.name;
    if (
      serviceName === noteFieldsMapper.contractor_arrivedon_site || 
      serviceName === noteFieldsMapper.response_authorization
    ) {
      const pendingContractors = spillContractors?.filter(
        (contractor) =>
          contractor?.accepted === null && !contractor?.is_inactive
      );
      const isEveryContractorInactive = spillContractors?.every(contractor => contractor?.is_inactive);
      // If there are no contractor selected or if all the contractor in the spill are inactive then show the Add Contractor Popup
      const hasNoContractorsSelected = spillContractors?.length === 0 || isEveryContractorInactive;
      const hasNoLatitudeLongitude =
        !currentSpill?.data?.spill?.latitude &&
        !currentSpill?.data?.spill?.longitude;

      /* if the service is "Response Authorization" and if no Contractor is selected then show the popup to add Contractor or if added then check if any of the contractors are not accepted yet show the Modal to Accept the contractor when selecting this note and return from it */
      const isResponseAuthorizationService = serviceName == noteFieldsMapper.response_authorization;

      if(isResponseAuthorizationService) {
        if (hasNoContractorsSelected) {
          setContractorModalState({
            ...contractorModalState,
            message: "Add Contractor",
            visible: true,
            showGPSPopup: false,
            forGpsFields: false,
          });
        } else if(pendingContractors?.length > 0) {
          setContractorModalState({
            pendingContractors: pendingContractors,
            message: "Accept Contractor Response",
            visible: true,
            showGPSPopup: false,
            forGpsFields: false,
          });
        }
        return;
      }
    
      // If not Response Authorizatin then consider the Contractor Arrived Onsite Service
      if (hasNoLatitudeLongitude) {
        setContractorModalState({
          ...contractorModalState,
          message: "",
          visible: true,
          forGpsFields: true,
          showGPSPopup: true
        });
      }
    }
  };

  const closeUpdateContractorPromptHandler = (messageRecieved = "") => {
    if (
      messageRecieved === CONTRACTOR_PROMPT_MESSAGE_TEXT?.ADD_CONTACTOR ||
      messageRecieved ===
        CONTRACTOR_PROMPT_MESSAGE_TEXT?.ACCEPT_CONTRACTOR_RESPONSE ||
      messageRecieved === ""
    ) {
      setContractorModalState({
        ...contractorModalState,
        visible: false,
        showGPSPopup: true
      });
      setShowNoteCards(true);
      setShowCreateNote(false);
      setShowEditNote(false);
    }
  };

  const updateSpillToAllowAddingArrivedService = (
    acceptedContractors,
    contractorResponseIsAccepted
  ) => {

    // As the Contractor Arrived Onsite service can be created without adding any contractor then we can perform action ased on length and not abort to create a note if te contractor is not added
    
    let contractorAdminsToCreate = [];
    if(acceptedContractors?.length > 0) {
      const existingContractors = [...currentSpill?.data?.spillContractors];
      const contractorsToUpdate = existingContractors?.map((spillContractor) => {
        acceptedContractors?.forEach((acceptedContractor) => {
          if (acceptedContractor?.id === spillContractor.id) {
            spillContractor = acceptedContractor;
          }
        });
        return spillContractor;
      });
  
      acceptedContractors?.map((accpetedContractor) =>
        contractorAdminsToCreate?.push({
          contractorId: accpetedContractor.contractor_id,
          contractorAddressId: accpetedContractor.addressId,
          adminId: null,
          info: "",
          pix: null,
          spillSummary: null,
          contractorInv: null,
          wasteDoc: null,
          contractorInvoice: "",
          invNo: "",
          finalContractorInv: "",
          savings: "",
          pesPaid: null,
          contractorPaid: null,
          transToCt: null,
          payBy: null,
          responseTime: "00:00",
          isRemoved: false,
          isMain: false,
          isComplete: false,
          pesInvNo: null,
          pesInvAmount: null,
          status: null,
          rejectReason: null,
        })
      );
  
      currentSpill.data.spillContractors = contractorsToUpdate;
    }
    
    if(gpsFieldState?.lat && gpsFieldState?.long) {
      currentSpill.data.spill.latitude = gpsFieldState?.lat;
      currentSpill.data.spill.longitude = gpsFieldState?.long;
    }

    const completeSpill = currentSpill?.data;
    let formData = new FormData();
    formData.append("completeSpill", JSON.stringify(completeSpill));
    formData.append("adminData", JSON.stringify(contractorAdminsToCreate));

    updateSpillForAddingArrivedOnService(formData);
    
    if (
      contractorResponseIsAccepted === false
    ) {
      closeUpdateContractorPromptHandler(contractorModalState?.message);
    }
  };

  const executeScroll = () =>
    myRef?.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <CustomSnackBar
        open={!!errorMessage}
        severity="error"
        snackBarMessage={errorMessage}
        className="error_snackbar"
        onClose={hideError}
      />
      {onSearch && (
        <Grid item xs={12} style={{ textAlign: "Left", marginTop: "15px" }}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => closeViewSpill()}
          >
            Back
          </Button>
        </Grid>
      )}
      {currentSpill?.loading || newNoteCreation?.loading ? (
        <div className={classes.rootLoader}>
          <CircularProgress style={{ width: "80px", height: "80px" }} />
        </div>
      ) : (
        <>
          {currentSpill?.success ? (
            <Paper elevation={3} className={classes.spillPaper}>
              <div className={classes.header}>
                <Typography variant="subtitle1"> Spill Details</Typography>
                <div>
                  <ZoomInOutlinedIcon />
                  <ZoomOutOutlinedIcon />
                  <PrintOutlinedIcon />
                  <SupervisorAccountOutlinedIcon />
                </div>
              </div>
              <div className="flex">
                <div className={classes.detailHeading}>
                  Spill {spillData?.spill?.job_no} -- Display Mode
                </div>
                <div className={classes.detailHeading}>
                  Opened On: {getFormattedTime(spillData?.spill?.opened_on)}
                </div>
              </div>

              <div className="flex">
                <div>
                  {currentUser.data?.role?.permission?.close_spills ? (
                    <CustomMenu
                      // allowCloseSpill={allowCloseSpill}
                      options={
                        statuses
                          ? spillStatuses.map((x) => `${x?.status}: ${x?.name}`)
                          : []
                      }
                      handleChange={(val) => handleStatusChange(val)}
                      buttonText="Status Change"
                    />
                  ) : null}
                </div>

                <div className={classes.detailHeading}>
                  {spillData?.spill?.closed_on === "Still Open"
                    ? statusVisibility(
                        spillData?.spill?.status,
                        currentUser?.data?.role?.role
                      )
                    : spillData?.spill?.closed_on === "Re-Opened"
                    ? spillData?.spill?.closed_on
                    : `Closed On: ${getFormattedTime(
                        spillData?.spill?.closed_on
                      )}`}
                </div>
              </div>

              <div className="flex">
                <div
                  style={{
                    userSelect: "auto",
                    display: "block",
                    textAlign: "justify",
                  }}
                >
                  {currentUser.data?.role?.permission?.edit_spills &&
                    !isContractorInactive() && (
                      <div
                        onClick={() => updateSpill(id)}
                        className={classes.linkButton}
                      >
                        Edit Spill
                      </div>
                    )}
                  {(currentUser.data?.role?.permission?.edit_spills ||
                    isCoporatePersonnel()) &&
                  !isContractorInactive() &&
                  !isContractor() &&
                  currentSpill?.data &&
                  printNotes ? (
                    <a
                      className={classes.linkButton}
                      onClick={!pdfLoader && generatePdfDocument}
                    >
                      {pdfLoader ? "Downloading Spill ..." : "Print Spill"}
                    </a>
                  ) : (
                    // <a
                    //   className={classes.linkButton}
                    //   href={pdfInfo.href}
                    //   download={pdfInfo.name}
                    //   style={
                    //     printNotes.length == 0
                    //       ? {
                    //           opacity: 0.5,
                    //           pointerEvents: 'none',
                    //         }
                    //       : null
                    //   }
                    // >
                    //   {printNotes.length == 0 ? 'loading' : 'Print Spill'}
                    // </a>
                    currentUser.data?.role?.permission?.edit_spills &&
                    !isContractor() && <>Loading</>
                  )}
                </div>
                <div>
                  {canSeeVoidOption(currentUser?.data?.role?.role) ? (
                    <div
                      onClick={() => voidPromptHandler(true)}
                      className={classes.linkButton}
                      style={{ textAlign: "right" }}
                    >
                      Void
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={classes.detailHeading}>
                    {showTeamDropdown == true ? (
                       <span>ICS Client</span>
                    ) : null}
                  </div>
                </div>

                {showAllButton() && (
                  <div>
                    <Card className={classes.downloadBanner}>
                      <div>
                        Attachments for this spill: {getTotalAttachments("all")}
                      </div>
                      {download?.loading ? (
                        <div>Downloading files....</div>
                      ) : (
                        getTotalAttachments("all") > 0 && (
                          <div
                            className={classes.downloadBannerLink}
                            onClick={async () => {
                              return downloadAll({
                                id: spillData?.spill.id,
                                type: "all",
                                name: `${
                                  spillData?.spill?.job_no || "FILE"
                                }.zip`,
                                spillId: currentSpill?.data?.spill?.id,
                                ipAddress: await getPublicIp(),
                              });
                            }}
                          >
                            Download all as {spillData?.spill?.job_no || "FILE"}
                            .zip
                          </div>
                        )
                      )}
                    </Card>
                  </div>
                )}
              </div>

              <AppBar position="static" color="primary">
                <Tabs
                  value={tabValue}
                  onChange={(e, newVal) => {
                    setTabValue(newVal);
                  }}
                  aria-label="edit tabs"
                >
                  {tabList.map((row, index) => (
                    <Tab
                      key={index}
                      label={row.label}
                      {...a11yProps(row.index)}
                      value={row.index}
                    ></Tab>
                  ))}
                </Tabs>
              </AppBar>

              <div style={{ marginTop: 10 }}>
                <TabPanel
                  value={tabValue}
                  index={0}
                  className={classes.tabPanel}
                >
                  <div className={classes.tabPanelPaper}>
                    <div>
                      <div className={classes.leftAlign}>
                        <TabDetail
                          // allowCloseSpill={allowCloseSpill}
                          detailObj={[
                            {
                              heading: "Client Organization Details",
                              subValues: [
                                spillOrg.name,
                                [
                                  spillOrg.city,
                                  spillOrg.state,
                                  spillOrg.country,
                                ],
                                { heading: "Tax ID", value: spillOrg.tax_id },
                              ],
                            },
                            {
                              heading: "Managed By",
                              subValues: [
                                spillData?.spill?.user?.full_name,
                                {
                                  heading: "Email",
                                  value: spillData?.spill?.user?.email,
                                },
                              ],
                            },
                            {
                              heading: "Status",
                              subValues: [
                                statusVisibility(
                                  spillData?.spill?.status,
                                  currentUser?.data?.role?.role
                                ),
                              ],
                            },
                            {
                              heading: "Type",
                              subValues: [spillData?.spill?.type],
                            },
                            {
                              heading: "Site Conditions",
                              subValues: [spillData?.spill?.conditions],
                            },
                            {
                              heading: "Address",
                              subValues: [
                                spillData?.spill?.address,
                                {
                                  heading: "Location Type",
                                  value: spillData?.spill?.location_type,
                                },
                                [
                                  spillData?.spill?.city,
                                  spillData?.spill?.state,
                                  spillData?.spill?.country,
                                ],
                              ],
                            },
                            {
                              heading: "Dock Door Number",
                              subValues: [spillData?.spill?.dock_door_number],
                            },
                            ...getConditionalFields(),
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>

                {!currentUser?.data?.role?.role?.includes("Contractor") && (
                  <>
                    <TabPanel
                      value={tabValue}
                      index={1}
                      className={classes.tabPanel}
                    >
                      <div className={classes.tabPanelPaper}>
                        <div className={classes.leftAlign}>
                          <TabDetail
                            // allowCloseSpill={false}
                            detailObj={[
                              {
                                heading: "National Agency",
                                subValues: [
                                  getAgency(
                                    spillData?.connection?.agency_national
                                  ),
                                  spillData?.connection?.agency_national
                                    ?.address || "[Unknown Address] ",
                                  [
                                    spillData?.connection?.agency_national
                                      ?.city || "[Unknown City] ",
                                    spillData?.connection?.agency_national
                                      ?.state || "[Unknown State] ",
                                    spillData?.connection?.agency_national
                                      ?.country || "[Unknown Country] ",
                                  ],
                                  {
                                    heading: "Phone",
                                    value:
                                      spillData?.connection?.agency_national
                                        ?.phone,
                                  },
                                  {
                                    heading: "Fax",
                                    value:
                                      spillData?.connection?.agency_national
                                        ?.fax,
                                  },
                                ],
                              },
                              {
                                heading: "State Agency",
                                subValues: [
                                  spillData?.connection?.agency?.name || "",
                                  spillData?.connection?.agency?.address ||
                                    "[Unknown Address]",
                                  [
                                    spillData?.connection?.agency?.city ||
                                      "[Unknown City] ",
                                    spillData?.connection?.agency?.state ||
                                      "[Unknown State] ",
                                    spillData?.connection?.agency?.country ||
                                      "[Unknown Country] ",
                                  ],
                                  {
                                    heading: "Phone",
                                    value: spillData?.connection?.agency?.phone,
                                  },
                                  {
                                    heading: "Fax",
                                    value: spillData?.connection?.agency?.fax,
                                  },
                                ],
                              },
                              {
                                heading: "National Incident No",
                                subValues: [
                                  spillData?.connection?.incident_no !==
                                  "undefined"
                                    ? spillData?.connection?.incident_no
                                    : null,
                                ],
                              },
                              {
                                heading: "State Incident No",
                                subValues: [
                                  spillData?.connection?.state_incident_no !==
                                  "undefined"
                                    ? spillData?.connection?.state_incident_no
                                    : null,
                                ],
                              },
                            ]}
                          />

                          <br />
                          <Typography
                            variant={"subtitle1"}
                            className={classes.detailHeading}
                          >
                            Contractors:
                          </Typography>
                          <Typography variant={"subtitle2"}>
                            {spillData?.spillContractors &&
                              spillData?.spillContractors?.map((e, id) => {
                                if (!e.is_inactive) {
                                  return (
                                    <div
                                      className={
                                        id ===
                                        spillData?.spillContractors.length - 1
                                          ? ""
                                          : classes.bottomSpace
                                      }
                                    >
                                      <div>
                                        <b>{e.label}</b>
                                        {!e.accepted && (
                                          <b style={{ color: "red" }}>
                                            - ( Pending Response / Acceptance )
                                          </b>
                                        )}
                                      </div>
                                      <div>
                                        {e?.address
                                          ? `${e?.address}, `
                                          : "[Unknown Address]"}
                                      </div>
                                      <div>
                                        {e?.city
                                          ? `${e?.city}, `
                                          : "[Unknown City] "}
                                        {e?.state
                                          ? `${e?.state}, `
                                          : "[Unknown State] "}
                                        {e?.country
                                          ? `${e?.country}, `
                                          : "[Unknown Country]"}
                                      </div>
                                      <div>
                                        {e?.phone
                                          ? `Phone: ${e?.phone}`
                                          : "Phone: Not added"}
                                      </div>
                                      <div>
                                        {e?.fax
                                          ? `Fax: ${e?.fax}`
                                          : "Fax: Not added"}
                                      </div>
                                      <div>
                                        {e?.email
                                          ? `Email: ${e?.email}`
                                          : "Email: Not added"}
                                      </div>
                                      {id === 0 && getEtaBox()}
                                    </div>
                                  );
                                }
                              })}
                          </Typography>
                          <br />
                          <Typography className={classes.inActiveHeading}>
                            Inactive contractors:
                          </Typography>
                          <Typography className={classes.inActive}>
                            {spillData?.spillContractors &&
                              spillData?.spillContractors?.map((e, id) => {
                                if (e.is_inactive) {
                                  return (
                                    <div
                                      className={classes.bottomSpace}
                                      style={{ color: "#ccc" }}
                                    >
                                      <b>{e.label}</b>
                                      <div>
                                        {e?.address
                                          ? `${e?.address}, `
                                          : "[Unknown Address]"}
                                      </div>
                                      <div>
                                        {e?.city
                                          ? `${e?.city}, `
                                          : "[Unknown City] "}
                                        {e?.state
                                          ? `${e?.state}, `
                                          : "[Unknown State] "}
                                        {e?.country
                                          ? `${e?.country}, `
                                          : "[Unknown Country]"}
                                      </div>
                                      <div>
                                        {e?.phone
                                          ? `Phone: ${e?.phone}`
                                          : "Phone: Not added"}
                                      </div>
                                      <div>
                                        {e?.fax
                                          ? `Fax: ${e?.fax}`
                                          : "Fax: Not added"}
                                      </div>
                                      <div>
                                        {e?.email
                                          ? `Email: ${e?.email}`
                                          : "Email: Not added"}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </Typography>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value={tabValue}
                      index={2}
                      className={classes.tabPanel}
                    >
                      <div className={classes.tabPanelPaper}>
                        <div className={classes.leftAlign}>
                          <TabDetail
                            // allowCloseSpill={false}
                            detailObj={[
                              {
                                heading: "Job No",
                                subValues: [spillData?.spill?.job_no],
                              },
                              {
                                heading: "Dates",
                                subValues: [
                                  {
                                    heading: "Opened",
                                    value: getFormattedTime(
                                      spillData?.spill?.opened_on
                                    ),
                                  },

                                  spillData?.spill?.closed_on ===
                                    "Still Open" ||
                                  spillData?.spill?.closed_on === "Re-Opened"
                                    ? spillData?.spill?.closed_on
                                    : {
                                        heading: "Closed",
                                        value: getFormattedTime(
                                          spillData?.spill?.closed_on
                                        ),
                                      },
                                ],
                              },
                              {
                                heading: "Shipper Name",
                                subValues: [spillData?.spill?.shipper_name],
                              },
                              {
                                heading: "Shipper Address",
                                subValues: [spillData?.spill?.shipper_address],
                              },
                              {
                                heading: "Amount Released",
                                subValues: [spillData?.spill?.amount_released],
                              },
                              {
                                heading: "Actual Amount Released",
                                subValues: [
                                  spillData?.spill?.actual_amount_released,
                                ],
                              },
                              {
                                heading: "Material Class",
                                subValues: [spillData?.spill?.material_class],
                              },
                              {
                                heading: "Material",
                                subValues: [
                                  spillData?.spill?.spill_material
                                    ? spillData?.spill?.spill_material?.name
                                    : "",
                                ],
                              },
                              {
                                heading: "Quantity Type Released",
                                subValues: [
                                  spillData?.spill?.quantity_type_released,
                                ],
                              },
                              {
                                heading: "Actual Quantity Type Released",
                                subValues: [
                                  spillData?.spill?.actual_quantity_type_released,
                                ],
                              },
                              {
                                heading: "Damaged Container Type",
                                subValues: [
                                  spillData?.spill?.damaged_container_type,
                                ],
                              },
                              {
                                heading: "Type of Damage",
                                subValues: [spillData?.spill?.damage_type],
                              },
                              {
                                heading: "Claim No",
                                subValues: [spillData?.spill?.claim_no],
                              },
                              {
                                heading: "UN #",
                                subValues: [spillData?.spill?.un_no],
                              },
                              {
                                heading: "Toggles",
                                subValues: [
                                  {
                                    heading: "Need Map?",
                                    value: spillValues.mapNeeded,
                                  },
                                  {
                                    heading: "Need 5800?",

                                    value: spillValues.need_5800,
                                  },
                                  {
                                    heading: "Is Waste?",
                                    value: spillValues.is_waste,
                                  },

                                  {
                                    heading: "Has Msds?",
                                    value: spillValues.has_msds,
                                  },

                                  {
                                    heading: "Is Hazmat?",
                                    value: spillValues.is_hazmat,
                                  },
                                  {
                                    heading: "Response Sent?",
                                    value: spillValues.response_sent,
                                  },
                                  {
                                    heading: "Potential Subrogation?",
                                    value: spillValues.subrogation,
                                  },
                                ],
                              },
                              {
                                heading: "Total Permeable Area",
                                subValues: [totalArea?.total_permeable_area],
                              },
                              {
                                heading: "Total Impermeable Area",
                                subValues: [totalArea?.total_impermeable_area],
                              },
                              {
                                heading: "Total Area Covered By Release",
                                subValues: [totalArea?.total],
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </>
                )}
                <TabPanel
                  value={tabValue}
                  index={3}
                  className={classes.tabPanel}
                >
                  <AdminViewTab
                    currentUser={currentUser}
                    showAdmin={showAdmin}
                    contractorOptions={contractorOptions}
                    downloadAdminFiles={downloadAdminFiles}
                    spillData={spillData}
                    downloadLoader={download}
                    attachmentHistory={attachmentHistory}
                    viewAdminAttachmentHistory={viewAdminAttachmentHistory}
                  />
                </TabPanel>
                {!currentUser?.data?.role?.role?.includes("Contractor") && (
                  <>
                    <TabPanel
                      value={tabValue}
                      index={4}
                      className={classes.tabPanel}
                    >
                      <div className={classes.tabPanelPaper}>
                        <div style={{ textAlign: "left", display: "flex" }}>
                          <Typography
                            variant={"subtitle1"}
                            className={classes.detailHeading}
                          >
                            Send Attachments:
                          </Typography>
                          <Typography variant="subtitle1">
                            &nbsp;{spillValues.send_attachment}
                          </Typography>
                        </div>

                        <Grid className={classes.root} container spacing={3}>
                          <Grid item xs={12} style={{ textAlign: "left" }}>
                            <Typography
                              variant={"subtitle1"}
                              className={classes.detailHeading}
                            >
                              Users for email:
                            </Typography>
                          </Grid>

                          {usersToEmail.map((user, index) => (
                            <Grid
                              item
                              xs={6}
                              style={{ textAlign: "left", paddingTop: "0px" }}
                            >
                              <Typography variant={"subtitle2"}>
                                <div>
                                  <InputLabel
                                    id="users-for-email-label"
                                    className={classes.usersForEmail}
                                  >
                                    {user.label}
                                  </InputLabel>
                                  <div className={classes.boldText}>
                                    {user.organization}
                                  </div>
                                  <div>
                                    {user.address
                                      ? user.address
                                      : "[Unknown Address]"}
                                  </div>
                                  <div>
                                    {user.city ? user.city : "[Unknown City]"}
                                  </div>
                                  <div>
                                    Phone:
                                    {user.phone ? user.phone : "Not added"}
                                  </div>
                                  <div>
                                    Email:
                                    {user.email ? user.email : "Not added"}
                                  </div>
                                </div>
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                        <br />
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: "left" }}
                          ref={myRef}
                        >
                          <Typography variant={"subtitle2"}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Typography
                                variant={"subtitle1"}
                                className={classes.detailHeading}
                              >
                                Rate:
                              </Typography>
                              <div
                                style={{
                                  padding: "1px 0px 0px 3px",
                                  alignSelf: "center",
                                }}
                              >
                                {spillData?.spill?.rate ||
                                  spillData?.spill?.client_organization?.rate}
                              </div>
                            </div>
                          </Typography>
                        </Grid>

                        <div style={{ textAlign: "left" }}>
                          <Typography
                            variant={"subtitle1"}
                            className={classes.detailHeading}
                          >
                            Notes:
                          </Typography>
                          <div className={classes.rightAlign}>
                            <div className={classes.inlineBlock}>
                              <Card className={classes.downloadBanner}>
                                <div>
                                  Note Attachments for this spill:&nbsp;
                                  {getTotalAttachments("note")}
                                </div>
                                {download?.loading ? (
                                  <div>Downloading files....</div>
                                ) : (
                                  getTotalAttachments("note") > 0 && (
                                    <div
                                      className={classes.downloadBannerLink}
                                      onClick={async () =>
                                        downloadAll({
                                          id: spillData?.spill.id,
                                          type: "note",
                                          name: `${spillData?.spill?.job_no}.zip`,
                                          spillId:
                                            currentSpill?.data?.spill?.id,
                                          ipAddress: await getPublicIp(),
                                        })
                                      }
                                    >
                                      Download all as {spillData?.spill?.job_no}
                                      .zip
                                    </div>
                                  )
                                )}
                              </Card>
                            </div>
                          </div>

                          {noteLoader || paginatedNotes.loading ? (
                            <div>
                              <div className={classes.rootLoader}>
                                <CircularProgress
                                  style={{ width: "80px", height: "80px" }}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              {createNoteBtn()}
                              {showNoteCards && (
                                <div>
                                  {spillNotes.length > 0 ? (
                                    <div>
                                      <div className={classes.notesListScroll}>
                                        {spillNotes &&
                                          spillNotes.map((note, i) => (
                                            <NoteCardAccordion
                                              key={i}
                                              note={note}
                                              onEdit={() =>
                                                handleEditNoteClick(note)
                                              }
                                              showTimeStamps={canSeeStampsOrAmounts(
                                                currentUser?.data?.role?.role,
                                                currentUser?.data
                                                  ?.client_organization
                                                  ?.timestamp_visibility
                                              )}
                                              showMonetaryAmounts={canSeeStampsOrAmounts(
                                                currentUser?.data?.role?.role,
                                                currentUser?.data
                                                  ?.client_organization
                                                  ?.monetary_visibility
                                              )}
                                              isEditable={checkIfEditable(note)}
                                              isDisabled={checkIfDisabled(note)}
                                              toolTipText={
                                                checkIfDisabled(note) &&
                                                "Cannot edit this note. The service added in this note is removed from the organization profile."
                                              }
                                              showTimeStamp={
                                                [1, 2, 3, 4, 11].includes(
                                                  currentUser?.data?.role_id
                                                )
                                                  ? true
                                                  : currentClient?.data
                                                      ?.timestamp_visibility ??
                                                    false
                                              }
                                            />
                                          ))}
                                      </div>
                                                                            <Divider className={tableClasses.divider} />
                                      <div>
                                        {canSeeStampsOrAmounts(
                                          currentUser?.data?.role?.role,
                                          currentUser?.data?.client_organization
                                            ?.monetary_visibility
                                        ) && (
                                          <TableContainer component={Paper} className={tableClasses.tableContainer}>
                                            <Table aria-label="simple table">
                                              <TableHead className={tableClasses.tableHeader}>
                                                <TableRow className={tableClasses.tableHeaderRow}>
                                                  <TableCell className={tableClasses.tableHeaderCell}> Service </TableCell>
                                                  <TableCell className={tableClasses.tableHeaderCell}> Status</TableCell>
                                                  <TableCell align="center" className={tableClasses.tableHeaderCell}> Tally </TableCell>
                                                  <TableCell align="center" className={tableClasses.tableHeaderCell}> Total Hours </TableCell>
                                                  <TableCell className={tableClasses.tableHeaderCell}> Cost ($) </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {notesSummary.length > 0 && notesSummary.map((note, index) => {
                                                    return (
                                                      <TableRow key={index} className={tableClasses.tableBodyRow}>
                                                        <TableCell width={475} className={tableClasses.tableBodyCell}> {note?.service} </TableCell>
                                                        <TableCell width={75} className={tableClasses.tableBodyCell}> {note?.status} </TableCell>
                                                        <TableCell width={50} align="center" className={tableClasses.tableBodyCell}> {note?.tally} </TableCell>
                                                        <TableCell align="center" className={tableClasses.tableBodyCell}> {(note?.totalHours)?.toFixed(2)} </TableCell>
                                                        <TableCell className={tableClasses.tableBodyCell}> ${(note?.cost)?.toFixed(2)} </TableCell>
                                                      </TableRow>
                                                    )
                                                  })
                                                }
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        )}
                                      </div>
                                      <Card className={classes.spacedTotalCard}>
                                        <Grid container justify="space-between">
                                          <Grid item xs={3} className={classes.totalLabel}>
                                            &nbsp;&nbsp; OVERALL TOTAL
                                          </Grid>
                                          <Grid item xs={4}></Grid>
                                          <Grid item xs={4} className={classes.totalArea}>
                                            <div className={classes.spaceBetween}>
                                              <div style={{ width: 80, paddingLeft: 38 }}>{totalTally?.toString().padStart(2, "0")}</div>
                                              <div style={{ width: 40 }}>{(spillNotesData?.totalHours)?.toFixed(2)}h</div>
                                              <div style={{ width: 90 }}> &nbsp; ${(spillNotesData?.totalAmount)?.toFixed(2)}</div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </div>
                                  ) : (
                                    <Typography
                                      variant="subtitle2"
                                      style={{ lineHeight: 3 }}
                                    >
                                      Notes not attached
                                    </Typography>
                                  )}
                                </div>
                              )}
                            </>
                          )}

                          {showEditNote && (
                            <EditNote
                              openAreaCoverModal={openAreaCoverModal}
                              setOpenAreaCoverModal={setOpenAreaCoverModal}
                              Notes={services}
                              currentNote={currentNote}
                              executeScroll={executeScroll}
                              closeNoteFrom={(btnCloseTrue) => {
                                setShowEditNote(false);
                                setShowNoteCards(true);
                                if (btnCloseTrue) {
                                  setNoteLoader(false);
                                } else {
                                  setNoteLoader(true);
                                }
                              }}
                              spillRate={
                                spillData?.spill?.rate ||
                                spillData?.spill?.client_organization?.rate
                              }
                              checkSelectedServiceHandler={
                                checkSelectedServiceHandler
                              }
                              setUpdateSpillNote={setUpdateSpillNote}
                              editNoteState={editNoteState}
                            />
                          )}
                          {showCreateNote && (
                            <CreateNote
                              openAreaCoverModal={openAreaCoverModal}
                              setOpenAreaCoverModal={setOpenAreaCoverModal}
                              executeScroll={executeScroll}
                              Notes={services}
                              spillRate={
                                spillData?.spill?.rate ||
                                spillData?.spill?.client_organization?.rate
                              }
                              closeNoteFrom={() => {
                                setShowNoteCards(true);
                                setShowCreateNote(false);
                              }}
                              checkSelectedServiceHandler={
                                checkSelectedServiceHandler
                              }
                              submitNote={createNewNote}
                              setSelectedVideoFiles={setSelectedVideoFiles}
                            />
                          )}

                          {/* uncomment this code to get pagination back make sure to pass shouldPaginate true in saga api call */}
                          {/* {noteLoader || paginatedNotes.loading ? null : (
                            <div className={classes.notePaginationWrapper}>
                              {showCreateNote ? null : (
                                <div className={classes.paginationWrapper}>
                                  <TablePagination
                                    classes={{
                                      toolbar: classes.paginationToolBar,
                                      select: classes.paginationSelect,
                                    }}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={totalNotes}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                      inputProps: {
                                        'aria-label': 'rows per page',
                                      },
                                      native: true,
                                    }}
                                    onChangePage={
                                      handleNotesAndAttachmentsChangePage
                                    }
                                    onChangeRowsPerPage={
                                      handleNotesAndAttachmentsChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                    labelDisplayedRows={(labels) => {
                                      const { from, to, count, page } = labels;
                                      if (from && to && count) {
                                        return (
                                          <PaginationButtons
                                            to={to}
                                            from={from}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={
                                              handleNotesAndAttachmentsChangePage
                                            }
                                            page={page}
                                            setIsAppendNoteLast={
                                              setIsAppendNoteLast
                                            }
                                          />
                                        );
                                      }
                                      return '';
                                    }}
                                  />
                                </div>
                              )}
                              
                            </div>
                          )} */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {createNoteBtn()}
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value={tabValue}
                      index={5}
                      className={classes.tabPanel}
                    >
                      <div className={classes.tabPanelPaper}>
                        <div style={{ textAlign: "left" }}>
                          <span>
                            <div>
                              {spillReserves?.length > 0 ? (
                                <div>
                                  <div>
                                    <Card
                                      className={classes.headerCard}
                                      style={{ margin: "10px 15px" }}
                                    >
                                      <CardContent
                                        style={{
                                          padding: "10px",
                                          backgroundColor: "#e6e6e6d6",
                                          fontWeight: 600,
                                          color: "#6aa16c",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        ></div>
                                        <Grid container spacing={3}>
                                          <Grid item xs={2}>
                                            <div
                                              className={classes.leftAlign}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className={classes.leftAlign}>
                                              Date
                                            </div>
                                          </Grid>

                                          <Grid item xs={2}>
                                            <div className={classes.leftAlign}>
                                              Amount
                                            </div>
                                          </Grid>
                                          <Grid item xs={5}>
                                            <div className={classes.leftAlign}>
                                              Approved by
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                    {spillReserves?.map((reserve, i) => (
                                      <Card
                                        className={classes.headerCard}
                                        style={{ margin: "10px 15px" }}
                                      >
                                        <CardContent
                                          style={{ padding: "10px" }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                              {isPESUser_2(
                                                currentUser.data?.role?.role
                                              ) ? (
                                                <div
                                                  className={classes.leftAlign}
                                                >
                                                  <Button
                                                    color="primary"
                                                    onClick={() => {
                                                      setCurrentReserve(
                                                        reserve
                                                      );
                                                      setShowEditReserve(true);
                                                    }}
                                                  >
                                                    Edit
                                                  </Button>
                                                </div>
                                              ) : (
                                                <div
                                                  className={classes.leftAlign}
                                                ></div>
                                              )}
                                            </Grid>
                                            <Grid item xs={3}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {Moment(
                                                  new Date(reserve.created_at)
                                                )?.format("MM-DD-YY")}
                                              </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {reserve.amount}
                                              </div>
                                            </Grid>
                                            <Grid item xs={5}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {reserve.user?.full_name}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{ lineHeight: 3 }}
                                >
                                  No reserves
                                </Typography>
                              )}
                            </div>
                            {isPESUser_2(currentUser?.data?.role?.role) && (
                              <div style={{ textAlign: "right" }}>
                                <a
                                  href
                                  onClick={() => setShowCreateReserve(true)}
                                  style={{ cursor: "pointer", color: "green" }}
                                >
                                  Create Reserve
                                </a>
                              </div>
                            )}
                            {showEditReserve && (
                              <EditReserve
                                currentSpillId={spillData?.spill?.id}
                                admins={admins}
                                currentReserve={currentReserve}
                                closeReserveForm={() =>
                                  setShowEditReserve(false)
                                }
                              />
                            )}
                          </span>
                          {showCreateReserve && (
                            <CreateReserve
                              currentSpillId={spillData?.spill?.id}
                              currentUser={currentUser?.data}
                              admins={admins}
                              closeReserveForm={() =>
                                setShowCreateReserve(false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </>
                )}
                <TabPanel
                  value={tabValue}
                  index={6}
                  className={classes.tabPanel}
                >
                  <div className={classes.tabPanelPaper}>
                    <HistoryTabPanel
                      notes={spillNotes}
                      spillId={currentSpill.data.spill.id}
                    />
                  </div>
                </TabPanel>

                <TabPanel
                  value={tabValue}
                  index={8}
                  className={classes.tabPanel}
                >
                  <div className={classes.tabPanelPaper}>
                    <>
                      {reportingReqData.length > 0 ? (
                        <div>
                          {reportingReqData.map((reportingreq, index) => (
                            <div
                              key={index}
                              style={{ textAlign: "left" }}
                              className="tabDetail-format"
                            >
                              <h3>Reporting Requirement Information</h3>
                              <p>
                                <strong>Agency Name : </strong>
                                {reportingreq.name}
                              </p>
                              <p>
                                <strong>Jurisdiction : </strong>
                                {reportingreq.jurisdiction}
                              </p>
                              <p>
                                <strong>Petroleum Release Amount : </strong>
                                {reportingreq.petroleum_release_amount}
                              </p>
                              <p>
                                <strong>Petroleum Release Detail : </strong>
                                {reportingreq.petroleum_release}
                              </p>
                              <p>
                                <strong>Hazardous Material Amount : </strong>
                                {reportingreq.hazardous_material_amount}
                              </p>
                              <p>
                                <strong>Hazardous Material Detail : </strong>
                                {reportingreq?.hazardous_material}
                              </p>
                              <p>
                                <strong>Water Impact Amount : </strong>
                                {reportingreq.water_impact_amount}
                              </p>
                              <p>
                                <strong>Water Impact Detail : </strong>
                                {reportingreq.water_impact}
                              </p>
                              <p>
                                <strong>Soil Impact Amount : </strong>
                                {reportingreq.soil_impact_amount}
                              </p>
                              <p>
                                <strong>Soil Impact Detail : </strong>
                                {reportingreq.soil_impact}
                              </p>
                              <p>
                                <strong>Revision Date : </strong>
                                {reportingreq.revision_date}
                              </p>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p> No reporting requirements available.</p>
                      )}

                      <div>
                        <div
                          style={{ textAlign: "left" }}
                          className="tabDetail-format"
                        >
                          <h3>Special Instructions for Client</h3>
                          {wasteHandlingData?.hazardous_waste == null &&
                          wasteHandlingData?.non_hazardous_waste == null &&
                          spillOrg?.revision_date == null &&
                          spillOrg?.reporting == null &&
                          spillOrg?.reporting_instruction == null &&
                          spillOrg?.report_5800 == null &&
                          spillOrg?.instruction_5800 == null &&
                          spillOrg?.miscellaneous == null ? (
                            <p>No Data Available</p>
                          ) : (
                            <>
                              <p
                                className={
                                  wasteHandlingData?.hazardous_waste == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Haz waste : </strong>
                                {wasteHandlingData?.hazardous_waste}
                              </p>
                              <p
                                className={
                                  wasteHandlingData?.non_hazardous_waste == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Non-Haz Waste : </strong>
                                {wasteHandlingData?.non_hazardous_waste}
                              </p>
                              <p
                                className={
                                  spillOrg?.revision_date == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Revision Date : </strong>
                                {spillOrg.revision_date}
                              </p>
                              <p
                                className={
                                  spillOrg?.reporting == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Reporting : </strong>
                                {spillOrg.reporting === 1 ? "Yes" : "No"}
                              </p>
                              <p
                                className={
                                  spillOrg?.reporting_instruction == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Reporting Instruction : </strong>
                                {spillOrg.reporting_instruction}
                              </p>
                              <p
                                className={
                                  spillOrg?.report_5800 == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Report 5800 : </strong>
                                {spillOrg.report_5800 === 1 ? "Yes" : "No"}
                              </p>
                              <p
                                className={
                                  spillOrg?.instruction_5800 == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>5800 Instruction : </strong>
                                {spillOrg.instruction_5800}
                              </p>
                              <p
                                className={
                                  spillOrg?.miscellaneous == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Miscellaneous : </strong>
                                {spillOrg.miscellaneous}
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      {/* team and Terminal */}
                      {showTeamDropdown == true ? (
                        <div
                          style={{ textAlign: "left" }}
                          className="tabDetail-format"
                        >
                          <h3>Team Information</h3>
                          <p
                            className={
                              TeamTerminalData?.terminalName == null ? "hidden" : "show"
                            }
                          >
                            <strong>Terminal Name: </strong>
                            {TeamTerminalData?.terminalName}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.team == null ? "hidden" : "show"
                            }
                          >
                            <strong>Team Name: </strong>
                            {TeamTerminalData?.team}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.address == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>Address: </strong>
                            {TeamTerminalData?.address}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.city == null ? "hidden" : "show"
                            }
                          >
                            <strong>City: </strong>
                            {TeamTerminalData?.city}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.epa == null ? "hidden" : "show"
                            }
                          >
                            <strong>EPA: </strong>
                            {TeamTerminalData?.epa}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.phone == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>Phone Number: </strong>
                            {TeamTerminalData?.phone}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.stat == null ? "hidden" : "show"
                            }
                          >
                            <strong>Status: </strong>
                            {TeamTerminalData?.stat}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.state == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>State: </strong>
                            {TeamTerminalData?.state}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.country == null ? "hidden" : "show"
                            }
                          >
                            <strong>Country: </strong>
                            {TeamTerminalData?.country}
                          </p>{" "}
                          <p
                            className={
                              TeamTerminalData?.zip == null ? "hidden" : "show"
                            }
                          >
                            <strong>Zipcode: </strong>
                            {TeamTerminalData?.zip}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{ textAlign: "left" }}
                            className="tabDetail-format"
                          >
                            <h3>Facility Information</h3>
                            {facilityData?.name == null &&
                            facilityData?.city == null &&
                            facilityData?.country == null &&
                            facilityData?.epa_id == null &&
                            facilityData?.generator_status == null &&
                            facilityData?.internal_id == null &&
                            facilityData?.phone_number == null &&
                            facilityData?.address == null &&
                            facilityData?.region == null &&
                            facilityData?.state == null &&
                            facilityData?.is_active == null &&
                            facilityData?.zip_code == null ? (
                              <p>No Data Available</p>
                            ) : (
                              <>
                                <p
                                  className={
                                    facilityData?.name == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Name: </strong>
                                  {facilityData?.name}
                                </p>
                                <p
                                  className={
                                    facilityData?.city == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility City: </strong>
                                  {facilityData?.city}
                                </p>
                                <p
                                  className={
                                    facilityData?.country == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Country: </strong>
                                  {facilityData?.country}
                                </p>
                                <p
                                  className={
                                    facilityData?.epa_id == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility EPA ID: </strong>
                                  {facilityData?.epa_id}
                                </p>
                                <p
                                  className={
                                    facilityData?.generator_status == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility General Status: </strong>
                                  {facilityData?.generator_status}
                                </p>
                                <p
                                  className={
                                    facilityData?.internal_id == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Internal ID: </strong>
                                  {facilityData?.internal_id}
                                </p>
                                <p
                                  className={
                                    facilityData?.phone_number == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Phone Number: </strong>
                                  {facilityData?.phone_number}
                                </p>
                                <p
                                  className={
                                    facilityData?.region == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Region: </strong>
                                  {facilityData?.region}
                                </p>
                                <p
                                  className={
                                    facilityData?.state == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility State: </strong>
                                  {facilityData?.state}
                                </p>
                                <p
                                  className={
                                    facilityData?.zip_code == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Zip Code: </strong>
                                  {facilityData?.zip_code}
                                </p>
                                <p
                                  className={
                                    facilityData?.address == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Address: </strong>
                                  {facilityData?.address}
                                </p>
                                <p
                                  className={
                                    facilityData?.is_active == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Active: </strong>
                                  {facilityData?.is_active ? "Yes" : "No"}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </TabPanel>
              </div>
            </Paper>
          ) : (
            <div> The spill you are trying to view was not found </div>
          )}
        </>
      )}

      <div>
        {
          <WholeScreenModal
            show={spillVoid.showVoidPrompt}
            hide={() => voidPromptHandler(false)}
          >
            <Prompt
              response={(response) => {
                response
                  ? clearSpillDataHandler()
                  : setSpillVoid({ showVoidPrompt: false, isVoid: false });
              }}
              headingText={voidText.voidSpillPromptHeading}
              question={{
                question: voidText.voidSpillPromptText,
                note: voidText.voidSpillPromptTextNote,
              }}
              acceptText={voidText.voidSpillPromptPromptAcceptBtn}
              rejectText={voidText.voidSpillPromptPromptRejectBtn}
              isClintOrg={false}
              isSpillVoid={true}
            />
          </WholeScreenModal>
        }
      </div>

      <div>
        {
          <WholeScreenModal
            disableBackdropClick
            disableEscapeKeyDown
            show={contractorModalState.visible}
            hide={() =>
              setContractorModalState({
                ...contractorModalState,
                visible: false,
                showGPSPopup: false
              })
            }
          >
            {contractorModalState.visible && (
              <UpdateContractorsPrompt
                response={(response, contractorResponseIsAccepted) => {
                  response
                    ? updateSpillToAllowAddingArrivedService(
                        contractorModalState?.pendingContractors,
                        contractorResponseIsAccepted
                      )
                    : closeUpdateContractorPromptHandler(
                        contractorModalState?.message
                      );
                }}
                gpsFieldState={gpsFieldState}
                areContractorsEmpty={
                  currentSpill?.data?.spillContractor
                    ? currentSpill?.data?.spillContractor.length === 0
                    : false
                }
                setGPSFieldState={setGPSFieldState}
                message={contractorModalState?.message}
                contractorModalState={contractorModalState}
                setContractorModalState={setContractorModalState}
                pendingContractors={contractorModalState?.pendingContractors}
                allContractorsAccepted={currentSpill?.existingContractors}
                addContractorText={
                  contractorModalText.spillAddContractorModalText
                }
                acceptHeadingText={
                  contractorModalText.acceptSpillContractorModalHeading
                }
                addHeadingText={
                  contractorModalText.addSpillContractorModalHeading
                }
                descriptionText={contractorModalText.spillContractorModalText}
                acceptText={contractorModalText.spillContractorModalUpdateBtn}
                rejectText={contractorModalText.spillContractorModalCancelBtn}
                loading={spillContractorsUpdateData?.loading}
              />
            )}
          </WholeScreenModal>
        }
      </div>
    </>
  );
};

const mapStateToProps = ({
  spill: {
    currentSpill,
    currentSpillServices,
    currentNoteHistory,
    newNoteCreation,
    totalPrintableNotes,
    paginatedNotes,
    status: { statuses, statusesLoading },
    spillAdminAttachmentHistory,
    noteVideosUpload,
    spillContractorsUpdateData,
    updateStatusState,
    editNoteState,
  },
  contractor: { contractorsWithAddress },
  download,
  client: { currentClient, getIcsTeamTerminal },
}) => ({
  currentSpill,
  contractorsWithAddress,
  currentSpillServices,
  currentNoteHistory,
  currentClient,
  download,
  newNoteCreation,
  totalPrintableNotes,
  paginatedNotes,
  noteVideosUpload,
  statuses,
  spillAdminAttachmentHistory,
  spillContractorsUpdateData,
  updateStatusState,
  editNoteState,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  getCompleteSpill: bindActionCreators(spillActions.getCompleteSpill, dispatch),
  closeSpill: bindActionCreators(spillActions.closeSpill, dispatch),
  updateSpillForAddingArrivedOnService: bindActionCreators(
    spillActions.updateSpillForAddingArrivedOnService,
    dispatch
  ),
  getContractorsWithAddress: bindActionCreators(
    contractorActions.getContractorsWithAddress,
    dispatch
  ),
  getClientAssociatedNotesServices: bindActionCreators(
    spillActions.getClientAssociatedNotesServices,
    dispatch
  ),
  createSingleNote: bindActionCreators(spillActions.createSingleNote, dispatch),
  downloadAll: bindActionCreators(downloadActions.downloadAll, dispatch),
  downloadAdminFiles: bindActionCreators(
    downloadActions.downloadAdminFiles,
    dispatch
  ),
  getOrganization: bindActionCreators(clientActions.getOrganization, dispatch),
  getNoteHistory: bindActionCreators(spillActions.getNoteHistory, dispatch),
  getTotalNotesForPrintSpill: bindActionCreators(
    spillActions.getTotalNotesForPrintSpill,
    dispatch
  ),
  getPaginatedNotes: bindActionCreators(
    spillActions.getPaginatedNotes,
    dispatch
  ),
  updateStatus: bindActionCreators(spillActions.updateStatus, dispatch),
  getStatuses: bindActionCreators(spillActions.getStatuses, dispatch),
  getSpillAdminAttachmentHistory: bindActionCreators(
    spillActions.getSpillAdminAttachmentHistory,
    dispatch
  ),
  uploadNoteVideos: bindActionCreators(spillActions.uploadNoteVideos, dispatch),
  clearSpillData: bindActionCreators(spillActions.clearSpillData, dispatch),
  getIcsTeamTerminal: bindActionCreators(
    clientActions.getIcsTeamTerminal,
    dispatch
  ),
  noteWorkTimeValidationFail: bindActionCreators(
    spillActions.noteWorkTimeValidationFail,
    dispatch
  ),
});

ViewSpill.propTypes = {
  currentSpill: PropTypes.object.isRequired,
  newNoteCreation: PropTypes.object.isRequired,
  getCompleteSpill: PropTypes.func.isRequired,
  getAdmins: PropTypes.func.isRequired,
  contractorsWithAddress: PropTypes.object.isRequired,
  getContractorsWithAddress: PropTypes.func.isRequired,
  getClientAssociatedNotesServices: PropTypes.func.isRequired,
  createSingleNote: PropTypes.func.isRequired,
  downloadAll: PropTypes.func.isRequired,
  downloadAdminFiles: PropTypes.func.isRequired,
  currentNoteHistory: PropTypes.object.isRequired,
  totalPrintableNotes: PropTypes.object.isRequired,
  paginatedNotes: PropTypes.object.isRequired,
  noteVideosUpload: PropTypes.object.isRequired,
  currentClient: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  spillContractorsUpdateData: PropTypes.object.isRequired,
  editNoteState: PropTypes.object.isRequired,
  getIcsTeamTerminal: PropTypes.func.isRequired,
  noteWorkTimeValidationFail: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewSpill)
);
