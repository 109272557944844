export const transformSpillNotes = (data) => {
  const result = [];

  const groupedData = data.reduce((acc, item) => {
    let { service_id, service_type, type, hour, amount } = item;

    if (!service_id || !service_type) return acc;

    type = type || "-";
    hour = parseFloat(hour) || 0.0;
    amount = parseFloat(amount) || 0.0;

    const key = `${service_id}-${type}`;

    if (!acc[key]) {
      acc[key] = { service: service_type, status: type, tally: 0, totalHours: 0, cost: 0 };
    }

    acc[key].tally += 1;
    acc[key].totalHours += hour;
    acc[key].cost += amount;

    return acc;
  }, {});

  let totalTally = 0;

  for (const key in groupedData) {
    const { service, status, tally, totalHours, cost } = groupedData[key];
    result.push({ service, status, tally, totalHours, cost });
    totalTally += tally;
  }

  return { data: result, totalTally };
};